/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav.
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
import Analytics from "layouts/dashboards/analytics";
import Sales from "layouts/dashboards/sales";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import Settings from "layouts/pages/account/settings";
// import Billing from 'layouts/pages/account/billing'
// import Invoice from 'layouts/pages/account/invoice'
// import Timeline from 'layouts/pages/projects/timeline'
// import PricingPage from 'layouts/pages/pricing-page'
// import Widgets from 'layouts/pages/widgets'
// import Charts from 'layouts/pages/charts'
// import Notifications from 'layouts/pages/notifications'
// import SignInBasic from 'layouts/authentication/sign-in/basic'
// import SignInCover from 'layouts/authentication/sign-in/cover'
// import SignInIllustration from 'layouts/authentication/sign-in/illustration'
// import SignUpCover from 'layouts/authentication/sign-up/cover'
// import ResetCover from 'layouts/authentication/reset-password/cover'

// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
import profilePicture from "assets/images/avatar.png";
// ZeroUno
import { config } from "variables/Config.js";
import authService from "services/auth.service";
import LoginPage from "views/auth/login.js";
import MainDashboard from "views/dashboard";
import UserProfile01 from "views/users/UserProfile.js";
import UsersTable from "views/users/UsersTable.js";
import UserForm from "views/users/UserForm.js";
import EnumerationTable from "views/enumerations/EnumerationTable.js";
import EnumerationForm from "views/enumerations/EnumerationForm.js";
import CompanyTable from "views/crm/CompanyTable.js";
import CompanyForm from "views/crm/CompanyForm.js";
import CustomerTable from "views/crm/CustomerTable.js";
import CustomerForm from "views/crm/CustomerForm.js";
import AgentTable from "views/crm/AgentTable.js";
import AgentForm from "views/crm/AgentForm.js";
import DoctorTable from "views/crm/DoctorTable.js";
import DoctorForm from "views/crm/DoctorForm.js";
import HealthcareFacilityTable from "views/crm/HealthcareFacilityTable.js";
import HealthcareFacilityForm from "views/crm/HealthcareFacilityForm.js";
import HealthDoctorTable from "views/crm/HealthDoctorTable.js";
import HealthDoctorForm from "views/crm/HealthDoctorForm.js";
import InvoiceTable from "views/crm/InvoiceTable.js";
import InvoiceForm from "views/crm/InvoiceForm.js";
import InvoiceTransactionTable from "views/crm/InvoiceTransactionTable.js";
import InvoiceTransactionForm from "views/crm/InvoiceTransactionForm.js";
import InvoiceTransactionCustomerForm from "views/crm/InvoiceTransactionCustomerForm.js";
import VisitTable from "views/crm/VisitTable.js";
import VisitForm from "views/crm/VisitForm.js";
import PathologyTable from "views/crm/PathologyTable.js";
import PathologyForm from "views/crm/PathologyForm.js";
import ProductTable from "views/crm/ProductTable.js";
import ProductForm from "views/crm/ProductForm.js";
import ProductModelTable from "views/crm/ProductModelTable.js";
import ProductModelForm from "views/crm/ProductModelForm.js";
import ProductionTable from "views/crm/ProductionTable.js";
import ProductionForm from "views/crm/ProductionForm.js";
import OrderTable from "views/crm/OrderTable.js";
import OrderForm from "views/crm/OrderForm.js";
import SponsorshipTable from "views/crm/SponsorshipTable.js";
import SponsorshipForm from "views/crm/SponsorshipForm.js";
import Statistics from "views/crm/Statistics.js";
import TransactionTable from "views/crm/TransactionTable.js";
import TransactionForm from "views/crm/TransactionForm.js";
import GlsForm from "views/crm/GlsForm.js";
import CongressForm from "views/crm/CongressForm";
import CongressTable from "views/crm/CongressTable";
import MaintenanceForm from "views/crm/MaintenanceForm";
import MaintenanceTable from "views/crm/MaintenanceTable";

const loggedUser = authService.user();

const routes = [
  {
    type: "collapse",
    name: loggedUser ? loggedUser.name + " " + loggedUser.surname : "",
    key: "loggedUser",
    icon: (
      <MDAvatar
        src={
          loggedUser && loggedUser.image
            ? config.SERVER_URL + "/download/" + loggedUser.image
            : profilePicture
        }
        alt="imgProfile"
        size="sm"
      />
    ),
    collapse: [
      {
        name: "Il Mio Profilo",
        key: "il-mio-profilo",
        component: <UserProfile01 />,
        route: "/myprofile",
        invisible: false,
      },

      {
        name: "Logout",
        key: "logout",
        route: "/login",
        component: <LoginPage />,
        invisible: false,
      },
    ],
  },
  { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    name: "Dashboards",
    key: "dashboards",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        type: "collapse",
        name: "Dashboard",
        key: "dashboard",
        icon: <Icon fontSize="medium">dashboard</Icon>,
        route: "/dashboards/main",
        component: <MainDashboard />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Area Amministrativa",
    key: "amministrazione",
    icon: <Icon fontSize="medium">supervisor_account</Icon>,
    collapse: [
      {
        name: "Cliente",
        key: "customer",
        route: "/customers/:idObj",
        component: <CustomerForm />,
        invisible: true,
      },
      {
        name: "Clienti",
        key: "customers",
        route: "/customers",
        component: <CustomerTable />,
        invisible: !(
          authService.isAdmin() ||
          authService.isAgente() ||
          authService.isTecnico() ||
          authService.isTecnicoAgente()
        ),
      },
      {
        name: "Statistiche",
        key: "statistics",
        route: "/stats",
        component: <Statistics />,
        invisible: !(
          authService.isAdmin() ||
          authService.isAgente() ||
          authService.isTecnico() ||
          authService.isTecnicoAgente()
        ),
      },
      {
        name: "Ordine",
        key: "order",
        route: "/customers/:idCustomer/orders/:idObj",
        component: <OrderForm />,
        invisible: true,
      },
      {
        name: "Ordini Cliente",
        key: "customerOrders",
        route: "/customers/:idCustomer/orders",
        component: <OrderTable />,
        invisible: true,
      },
      {
        name: "Ordini Vendita a Cliente",
        key: "customerOrders",
        route: "/customers/:idCustomer/salesorders",
        component: <OrderTable />,
        invisible: true,
      },
      {
        name: "Ordini Noleggio",
        key: "orders",
        route: "/orders",
        component: <OrderTable />,
        invisible: !(
          authService.isAdmin() ||
          authService.isAgente() ||
          authService.isTecnico() ||
          authService.isTecnicoAgente()
        ),
      },
      {
        name: "Ordini Vendita",
        key: "sales orders",
        route: "/salesorders",
        component: <OrderTable />,
        invisible: !(
          authService.isAdmin() ||
          authService.isAgente() ||
          authService.isTecnico() ||
          authService.isTecnicoAgente()
        ),
      },
      {
        name: "Pagamento",
        key: "payment",
        route: "/customers/:idCustomer/payments/:idObj",
        component: <TransactionForm />,
        invisible: true,
      },
      {
        name: "Pagamenti Cliente",
        key: "customerPayments",
        route: "/customers/:idCustomer/payments",
        component: <TransactionTable />,
        invisible: true,
      },
      {
        name: "Pagamenti",
        key: "payments",
        route: "/payments",
        component: <TransactionTable />,
        invisible: !(
          authService.isAdmin() ||
          authService.isAgente() ||
          authService.isTecnico() ||
          authService.isTecnicoAgente()
        ),
      },

      {
        name: "Spedizioni Gls",
        key: "orderShipment",
        route: "/customers/:idCustomer/orders/:idOrder/gls/:idObj",
        component: <GlsForm />,
        invisible: true,
      },
    ],
  },
  {
    type: "collapse",
    name: "Manutenzioni",
    key: "manutenzioniAdmin",
    icon: <Icon fontSize="medium">handyman</Icon>,
    collapse: [
      {
        name: "Manutenzione",
        key: "manutenzione",
        route: "/maintenances/:idObj",
        component: <MaintenanceForm />,
        invisible: true,
      },
      {
        name: "Manutenzioni",
        key: "manutenzioni",
        route: "/maintenances",
        component: <MaintenanceTable />,
        invisible: !authService.isAdmin(),
      },
    ],
  },
  {
    type: "collapse",
    name: "Produzione",
    key: "produzione",
    icon: <Icon fontSize="medium">supervisor_account</Icon>,
    invisible: !authService.isAdmin(),
    collapse: [
      {
        name: "Fornitore",
        key: "fornitore",
        route: "/suppliers/:idObj",
        component: <CustomerForm />,
        invisible: true,
      },
      {
        name: "Fornitori",
        key: "fornitori",
        route: "/suppliers",
        component: <CustomerTable />,
        invisible: !authService.isAdmin(),
      },
      {
        name: "Produzione",
        key: "production",
        route: "/productions/:idObj",
        component: <ProductionForm />,
        invisible: true,
      },
      {
        name: "Produzione",
        key: "productions",
        route: "/productions",
        component: <ProductionTable />,
        invisible: !authService.isAdmin(),
      },
      {
        name: "Fattura",
        key: "invoice",
        route: "/invoices/:idObj",
        component: <InvoiceForm />,
        invisible: true,
      },
      {
        name: "Fatture",
        key: "invoices",
        route: "/invoices",
        component: <InvoiceTable />,
        invisible: !authService.isAdmin(),
      },
      {
        name: "Pagamento",
        key: "invoiceTransaction",
        route: "/invoices/:idInvoice/invoiceTransactions/:idObj",
        component: <InvoiceTransactionForm />,
        invisible: true,
      },
      {
        name: "Pagamento",
        key: "invoiceTransaction",
        route: "/suppliers/:idCustomer/invoiceTransactions/:idObj",
        component: <InvoiceTransactionCustomerForm />,
        invisible: true,
      },
      {
        name: "Pagamenti",
        key: "transactions",
        route: "/invoiceTransactions",
        component: <InvoiceTransactionTable />,
        invisible: !authService.isAdmin(),
      },
      {
        name: "Pagamenti",
        key: "supplierTransactions",
        route: "/suppliers/:idCustomer/invoiceTransactions",
        component: <InvoiceTransactionTable />,
        invisible: true,
      },
      {
        name: "Pagamenti",
        key: "invoiceTransactions",
        route: "/invoices/:idInvoice/invoiceTransactions",
        component: <InvoiceTransactionTable />,
        invisible: true,
      },
    ],
  },
  {
    type: "collapse",
    name: "Area Commerciale",
    key: "commerciale",
    icon: <Icon fontSize="medium">store</Icon>,
    invisible: !(
      authService.isAgente() ||
      authService.isTecnicoAgente() ||
      authService.isAdmin()
    ),
    collapse: [
      {
        name: "Visita Medici",
        key: "visit",
        route: "/visits/:idObj",
        component: <VisitForm />,
        invisible: true,
      },
      {
        name: "Visite Medici",
        key: "visits",
        route: "/visits",
        component: <VisitTable />,
        invisible: false,
      },
      {
        name: "Struttura Sanitaria",
        key: "healthcareFacility",
        route: "/healthcareFacilities/:idObj",
        component: <HealthcareFacilityForm />,
        invisible: true,
      },
      {
        name: "Strutture Sanitarie",
        key: "HealthcareFacilities",
        route: "/healthcareFacilities",
        component: <HealthcareFacilityTable />,
        invisible: false,
      },
      {
        name: "Medico Struttura Sanitaria",
        key: "healthDoctor",
        route: "/healthDoctors/:idObj",
        component: <HealthDoctorForm />,
        invisible: true,
      },
      {
        name: "Medici Strutture Sanitarie",
        key: "HealthDoctors",
        route: "/healthDoctors",
        component: <HealthDoctorTable />,
        invisible: false,
      },
    ],
  },
  {
    type: "collapse",
    name: "Area Marketing",
    key: "marketing",
    icon: <Icon fontSize="medium">sports_tennis</Icon>,
    invisible: !authService.isAdmin(),
    collapse: [
      {
        name: "Sponsorizzazione",
        key: "sponsorship",
        route: "/sponsorships/:idObj",
        component: <SponsorshipForm />,
        invisible: true,
      },
      {
        name: "Sponsorizzazioni",
        key: "sponsorships",
        route: "/sponsorships",
        component: <SponsorshipTable />,
        invisible: !authService.isAdmin(),
      },
      {
        name: "Congresso",
        key: "congresso",
        route: "/congresses/:idObj",
        component: <CongressForm />,
        invisible: true,
      },
      {
        name: "Congressi",
        key: "congressi",
        route: "/congresses",
        component: <CongressTable />,
        invisible: !authService.isAdmin(),
      },
    ],
  },
  {
    type: "collapse",
    name: "Impostazioni",
    key: "impostazioni",
    icon: <Icon fontSize="medium">settings</Icon>,
    collapse: [
      {
        name: "Utente",
        key: "user",
        route: "/users/:idUser",
        component: <UserForm />,
        invisible: true,
      },
      {
        name: "Utenti",
        key: "users",
        route: "/users",
        component: <UsersTable />,
        invisible: !authService.isAdmin(),
      },
      {
        name: "Azienda",
        key: "company",
        route: "/companies/:idCompany",
        component: <CompanyForm />,
        invisible: true,
      },
      {
        name: "Aziende",
        key: "companies",
        route: "/companies",
        component: <CompanyTable />,
        invisible: !authService.isAdmin(),
      },
      {
        name: "Agente",
        key: "agent",
        route: "/agents/:idObj",
        component: <AgentForm />,
        invisible: true,
      },
      {
        name: "Agenti",
        key: "agents",
        route: "/agents",
        component: <AgentTable />,
        invisible: true,
      },
      {
        name: "Medico",
        key: "doctor",
        route: "/doctors/:idObj",
        component: <DoctorForm />,
        invisible: true,
      },
      {
        name: "Medici",
        key: "doctors",
        route: "/doctors",
        component: <DoctorTable />,
        invisible: !(
          authService.isAdmin() ||
          authService.isAgente() ||
          authService.isTecnico() ||
          authService.isTecnicoAgente()
        ),
      },
      {
        name: "Patologia",
        key: "pathology",
        route: "/pathologies/:idObj",
        component: <PathologyForm />,
        invisible: true,
      },
      {
        name: "Patologie",
        key: "pathologies",
        route: "/pathologies",
        component: <PathologyTable />,
        invisible: !authService.isAdmin(),
      },
      {
        name: "Modello Prodotto",
        key: "productModel",
        route: "/productModels/:idObj",
        component: <ProductModelForm />,
        invisible: true,
      },
      {
        name: "Modelli Prodotto",
        key: "productModels",
        route: "/productModels",
        component: <ProductModelTable />,
        invisible: !authService.isAdmin(),
      },
      {
        name: "Prodotto",
        key: "product",
        route: "/products/:idObj",
        component: <ProductForm />,
        invisible: true,
      },
      {
        name: "Prodotti",
        key: "products",
        route: "/products",
        component: <ProductTable />,
        invisible: !authService.isAdmin(),
      },
      {
        name: "Descrizioni",
        key: "enumerations",
        route: "/enumerations",
        component: <EnumerationTable />,
        invisible: !authService.isAdmin(),
      },
      {
        name: "Descrizione",
        key: "enumeration",
        route: "/enumerations/:idObj",
        component: <EnumerationForm />,
        invisible: true,
      },
    ],
  },
  { type: "divider", key: "divider-1" },
  {
    type: "collapse",
    name: "Dev-IT srl",
    key: "devit",
    href: "https://www.dev-it.it",
    icon: <Icon fontSize="medium">architecture</Icon>,
    noCollapse: true,
  },
];

export default routes;
