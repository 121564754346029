"use strict";

import http from "./http-common";
import authHeader from "./auth-header";

const getAll = (companyId) => {
  return http.axiosClient.get(`/maintenances?companyId=${companyId}`, {
    headers: authHeader(),
  });
};

const revalidate = (companyId, mutate) => {
  mutate([`/maintenances?companyId=${companyId}`, { headers: authHeader() }]);
};

const get = (id) => {
  return http.axiosClient.get(`/maintenances/${id}`, { headers: authHeader() });
};

const create = (data) => {
  return http.axiosClient.post("/maintenances", data, {
    headers: authHeader(),
  });
};

const update = (id, data) => {
  return http.axiosClient.put(`/maintenances/${id}`, data, {
    headers: authHeader(),
  });
};

const remove = (id) => {
  return http.axiosClient.delete(`/maintenances/${id}`, {
    headers: authHeader(),
  });
};

const sendMail = (id) => {
  return http.axiosClient.get(`/maintenances/mailpdf/${id}`, {
    headers: authHeader(),
  });
};

const sendMultiMail = (ids) => {
  return http.axiosClient.get(`/maintenances/mail/pdf/multi?ids=${ids}`, {
    headers: authHeader(),
  });
};

export default {
  getAll,
  get,
  create,
  update,
  remove,
  revalidate,
  sendMail,
  sendMultiMail,
};
