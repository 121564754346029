"use strict";

import http from "./http-common";
import authHeader from "./auth-header";
import useSWR from "swr";

const getAll = (companyId) => {
  const { data, error } = useSWR(
    [`/doctors?companyId=${companyId}`, { headers: authHeader() }],
    http.fetcherAuth
  );
  return {
    data: data || [],
    isLoading: !error && !data,
    error: error,
  };

  // return http.get(`/doctors?companyId=${companyId}`, { headers: authHeader() })
};
const revalidate = (companyId, mutate) => {
  mutate([`/doctors?companyId=${companyId}`, { headers: authHeader() }]);
};

const get = (id) => {
  return http.axiosClient.get(`/doctors/${id}`, { headers: authHeader() });
};

const create = (data) => {
  return http.axiosClient.post("/doctors", data, { headers: authHeader() });
};

const update = (id, data) => {
  return http.axiosClient.put(`/doctors/${id}`, data, {
    headers: authHeader(),
  });
};

const remove = (id) => {
  return http.axiosClient.delete(`/doctors/${id}`, { headers: authHeader() });
};

export default {
  getAll,
  get,
  create,
  update,
  remove,
  revalidate,
};
