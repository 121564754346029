"use strict";
import { useEffect, useContext, useState } from "react";
// @mui material components
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  ToggleButton,
  ToggleButtonGroup,
  Grid,
} from "@mui/material";

import Autocomplete from "@mui/material/Autocomplete";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
// import Grid from '@mui/material/Grid'
import { Link as MuiLink, Stack } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// import MDTypography from 'components/MDTypography'
import MDSnackbar from "components/MDSnackbar";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
// import MDDropdown from 'components/MDDropdown'

// Material Dashboard 2 PRO React example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import DataTable from "components/Tables/DataTable";

// zerouno
import authService from "services/auth.service";
import customerService from "services/customer.service";
import mainService from "services/order.service";
import doctorService from "services/doctor.service";
import enumerationService from "services/enumeration.service";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import ZUTitleForm from "components/ZeroUno/ZUTitleForm";
import utils from "utils/utils.js";
import RabbitContext from "context/rabbit.js";
import { config } from "variables/Config.js";
import MDTypography from "components/MDTypography";
import userService from "services/user.service";
import pathologyService from "services/pathology.service";

export default function OrderTable() {
  const location = useLocation();
  const { rabbit } = useContext(RabbitContext);

  const [objId, setObjId] = useState(0);
  const { idCustomer } = useParams();
  // const [enums, setEnums] = React.useState([])
  const [obj, setObj] = useState([]);
  const [objects, setObjects] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [agents, setAgents] = useState([]);
  const [patologie, setPatologie] = useState([]);
  const {
    data: doctors,
    isLoading,
    error,
  } = doctorService.getAll(rabbit.companyId);
  const [report, setReport] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState({
    sh: false,
    msg: "",
    color: "success",
  });
  const [openConfirm, setOpenConfirm] = useState(false);
  const {
    data: enums,
    isLoading: loadEnum,
    error: errEnums,
  } = enumerationService.findByType("orderState");
  const sales = useLocation().pathname.includes("sales");
  const [year, setYear] = useState(new Date().getFullYear());
  const [stato, setStato] = useState("tutti");

  useEffect(() => {
    fetchObjects();
  }, [sales, location, year]);

  useEffect(() => {
    fetchPatologie();
    if (rabbit.companyId) {
      fetchAgents();
    }
  }, [rabbit.companyId]);

  const fetchObjects = () => {
    if (idCustomer) {
      customerService
        .get(idCustomer)
        .then((response) => {
          setObj(response.data);
        })
        .then(() => {
          return mainService.findByCustomer(idCustomer, sales);
        })
        .then((response2) => {
          setObjects(
            response2.data.filter((s) => !(!rabbit.black && s.code === "50"))
          );
          setFiltered(
            response2.data.filter((s) => !(!rabbit.black && s.code === "50"))
          );
        })
        .catch((error) => {
          setShowSnackbar({
            sh: true,
            msg: error.response
              ? error.response.data.message
              : "errore di ricezione dati",
            color: "error",
          });
        });
    } else {
      mainService
        .getAll(rabbit.companyId, sales ? "1" : "0", year)
        .then((response) => {
          setObjects(
            response.data.filter((s) => !(!rabbit.black && s.code === "50"))
          );
          setFiltered(
            response.data.filter((s) => !(!rabbit.black && s.code === "50"))
          );
          setStato("tutti");
        })
        .catch((error) => {
          setShowSnackbar({
            sh: true,
            msg: error.response
              ? error.response.data.message
              : "errore di ricezione dati",
            color: "error",
          });
        });
    }
  };

  const fetchAgents = () => {
    userService
      .getAll()
      .then((response) => {
        const users = response.data;
        setAgents(
          users.filter(
            (item) =>
              item.id.toString() !== "5" &&
              (item.role === "agente" ||
                item.role === "tecnico_agente" ||
                item.role === "admin")
          )
        );
      })
      .catch((error) => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : "errore di ricezione dati",
          color: "error",
        });
      });
  };

  const fetchPatologie = () => {
    pathologyService.getAll().then(
      (response) => {
        setPatologie(response.data);
      },
      (error) => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : "errore di ricezione dati",
          color: "error",
        });
      }
    );
  };

  // Delete
  const deleteObject = () => {
    setOpenConfirm(false);
    mainService.remove(objId).then(
      (response) => {
        setShowSnackbar({
          sh: true,
          msg: "operazione effettuata con successo",
          color: "success",
        });
        fetchObjects();
      },
      (error) => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : "errore di ricezione dati",
          color: "error",
        });
      }
    );
  };

  const handleStato = (value) => {
    // options={['tutti', 'prossimi invii', 'prossimi ritiri', 'in consegna']}
    const ordini = objects.filter((s) => !(!rabbit.black && s.code === "50"));
    if (value === "tutti") {
      setFiltered(ordini);
    }
    if (value === "prossimi invii") {
      setFiltered(ordini.filter((item) => item.stateId === 1));
    }
    if (value === "prossimi ritiri") {
      setFiltered(ordini.filter((item) => item.stateId === 3 && !item.sale));
    }
    if (value === "in consegna") {
      setFiltered(ordini.filter((item) => item.stateId === 2));
    }
    setStato(value);
  };

  const columns = [
    {
      Header: "cliente",
      accessor: (row) =>
        row.customer.surname.toUpperCase() +
        " " +
        row.customer.name.toUpperCase(),
    },
    {
      Header: "dottore",
      accessor: (d) => {
        return d.doctor ? d.doctor.surname + " " + d.doctor.name : "";
      },
    },
    {
      Header: "numero",
      accessor: (d) => {
        return d.number + " " + d.code + (d.extension ? " [ext]" : "");
      },
    },
    {
      Header: "stato",
      accessor: (row) =>
        enumerationService.getEnumerationDescription(enums, row.stateId),
    },
    {
      Header: "data",
      accessor: (d) => {
        return utils.formatDate(d.dateOrder, "dd/MM/yyyy");
      },
    },
    {
      Header: sales ? "consegna" : "inizio",
      accessor: (d) => {
        return d.dateBegin ? utils.formatDate(d.dateBegin, "dd/MM/yyyy") : "-";
      },
    },
    ...(!sales
      ? [
          {
            Header: "giorni",
            accessor: "quantity",
          },
        ]
      : []),
    {
      Header: "prezzo",
      accessor: "price",
      Cell: ({ value, row }) => utils.formatFloat(value.toFixed(2)),
    },
    {
      Header: "totale",
      accessor: "totalAmount",
      Cell: ({ value, row }) => utils.formatFloat(value.toFixed(2)),
    },
    {
      Header: "da pagare",
      accessor: "toPay",
      Cell: ({ value, row }) => utils.formatFloat(value.toFixed(2)),
    },
    {
      Header: "seriale",
      accessor: (row) =>
        row.product
          ? row.product.serial +
            " | " +
            row.product.batch +
            " [" +
            (row.companyId === 1 ? "T" : "B") +
            "]"
          : "---",
    },
    ...(authService.isAdmin()
      ? [
          {
            Header: "agente",
            accessor: (d) => {
              return d.agent ? d.agent.surname + " " + d.agent.name : "";
            },
          },
          {
            Header: "tecnico",
            accessor: (d) => {
              return d.technician
                ? d.technician.surname + " " + d.technician.name
                : "";
            },
          },
          {
            Header: "utente",
            accessor: (row) => row.user.username,
          },
        ]
      : []),
    {
      Header: "azioni",
      sortable: false,
      filterable: false,
      width: 100,
      accessor: (row) => (
        <>
          <MDButton
            color="info"
            size="small"
            iconOnly
            component={Link}
            to={`/customers/${row.customer.id}/orders/${row.id}`}
            sx={{ mr: 1 }}
          >
            <Icon>edit</Icon>
          </MDButton>
          {!row.sale && (
            <MDButton
              color="info"
              size="small"
              iconOnly
              component={Link}
              to={`/customers/${row.customer.id}/orders/${row.id}?extension=1`}
              sx={{ mr: 1 }}
            >
              <Icon>add</Icon>
            </MDButton>
          )}
          {authService.isAdmin() && (
            <MDButton
              iconOnly
              color="error"
              size="small"
              onClick={() => {
                setObjId(row.id);
                setOpenConfirm(true);
              }}
            >
              <Icon>delete</Icon>
            </MDButton>
          )}
        </>
      ),
    },
  ];

  const handleGenerateReport = (reportConfig) => {
    // Costruisci l'URL con i parametri necessari
    let url = `${config.SERVER_URL}/orders/download/excel?companyId=${rabbit.companyId}`;
    if (reportConfig.type === "pdf") {
      url = `${config.SERVER_URL}/orders/download/pdf?companyId=${rabbit.companyId}`;
    }
    // Aggiungi i parametri aggiuntivi basati sulla configurazione del report
    if (reportConfig.fromDate) {
      url += `&fromDate=${reportConfig.fromDate}`;
    }
    if (reportConfig.toDate) {
      url += `&toDate=${reportConfig.toDate}`;
    }
    if (reportConfig.includeCliente) url += "&includeCliente=true";
    if (reportConfig.includeDottore) url += "&includeDottore=true";
    if (reportConfig.includeModello) url += "&includeModello=true";
    if (reportConfig.includeData) url += "&includeData=true";
    if (reportConfig.includeGiorni) url += "&includeGiorni=true";
    if (reportConfig.includePrezzo) url += "&includePrezzo=true";
    if (reportConfig.includeAgente) url += "&includeAgente=true";
    if (reportConfig.includePatologia) url += "&includePatologia=true";
    if (reportConfig.selectedAgent) {
      url += `&agentId=${reportConfig.selectedAgent.id}`;
    }
    if (
      reportConfig.selectedDoctors &&
      reportConfig.selectedDoctors.length > 0
    ) {
      const doctorIds = reportConfig.selectedDoctors
        .map((doctor) => doctor.id)
        .join(",");
      url += `&doctorIds=${doctorIds}`;
    }
    if (reportConfig.selectedPatologie.length > 0) {
      const patologieIds = reportConfig.selectedPatologie
        .map((patologia) => patologia.id)
        .join(",");
      url += `&patologieIds=${patologieIds}`;
    }
    // Apri il link in una nuova finestra/tab per il download
    window.open(url, "_blank");

    console.log("Downloading report with config:", reportConfig);
  };

  function ReportDialog({ open, onClose, onGenerate }) {
    const [reportType, setReportType] = useState("excel");
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [includeAgente, setIncludeAgente] = useState(true);
    const [includeCliente, setIncludeCliente] = useState(true);
    const [includeDottore, setIncludeDottore] = useState(true);
    const [includePatologia, setIncludePatologia] = useState(true);
    const [includeModello, setIncludeModello] = useState(true);
    const [includeData, setIncludeData] = useState(true);
    const [includeGiorni, setIncludeGiorni] = useState(true);
    const [includePrezzo, setIncludePrezzo] = useState(true);
    const [selectedAgent, setSelectedAgent] = useState(null);
    const [selectedDoctors, setSelectedDoctors] = useState([]);
    const [selectedPatologie, setSelectedPatologie] = useState([]);

    const handleReportTypeChange = (event, newType) => {
      if (newType !== null) {
        setReportType(newType);
      }
    };

    const handleAgentChange = (event, newValue) => {
      setSelectedAgent(newValue);
    };

    const handleDoctorChange = (event, newValue) => {
      setSelectedDoctors(newValue);
    };

    const handlePatologieChange = (event, newValue) => {
      setSelectedPatologie(newValue);
    };

    const handleGenerate = () => {
      const reportConfig = {
        type: reportType,
        fromDate,
        toDate,
        includeCliente,
        includeDottore,
        includeModello,
        includeData,
        includeGiorni,
        includePrezzo,
        includeAgente,
        includePatologia,
        selectedAgent,
        selectedDoctors,
        selectedPatologie,
      };
      onGenerate(reportConfig);
      onClose();
    };

    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Genera Report</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ padding: 2 }}>
            <Grid item xs={4}>
              <ToggleButtonGroup
                value={reportType}
                exclusive
                onChange={handleReportTypeChange}
                aria-label="tipo di report"
              >
                <ToggleButton value="excel" aria-label="excel">
                  excel
                </ToggleButton>
                <ToggleButton value="pdf" aria-label="pdf">
                  pdf
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={4}>
              <MDInput
                type="date"
                label="Dalla data"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <MDInput
                type="date"
                label="Alla data"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                options={agents}
                getOptionLabel={(option) => `${option.surname} ${option.name}`}
                renderInput={(params) => (
                  <TextField {...params} label="Agente" />
                )}
                value={selectedAgent}
                onChange={handleAgentChange}
              />
            </Grid>

            <Grid item xs={12}>
              <Autocomplete
                multiple
                options={doctors}
                getOptionLabel={(option) =>
                  `${option.surname} ${option.name} [id:${option.id}]`
                }
                renderInput={(params) => (
                  <TextField {...params} label="Dottori" />
                )}
                value={selectedDoctors}
                onChange={handleDoctorChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                options={patologie}
                getOptionLabel={(option) => `${option.name} [id: ${option.id}]`}
                renderInput={(params) => (
                  <TextField {...params} label="Patologie" />
                )}
                value={selectedPatologie}
                onChange={handlePatologieChange}
              />
            </Grid>
            <Grid item xs={12}>
              <MDTypography variant="h6">Includi:</MDTypography>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={includeCliente}
                    onChange={(e) => setIncludeCliente(e.target.checked)}
                  />
                }
                label="Cliente"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={includeAgente}
                    onChange={(e) => setIncludeAgente(e.target.checked)}
                  />
                }
                label="Agente"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={includeDottore}
                    onChange={(e) => setIncludeDottore(e.target.checked)}
                  />
                }
                label="Dottore"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={includeModello}
                    onChange={(e) => setIncludeModello(e.target.checked)}
                  />
                }
                label="Modello Prodotto"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={includePatologia}
                    onChange={(e) => setIncludePatologia(e.target.checked)}
                  />
                }
                label="Patologia"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={includeData}
                    onChange={(e) => setIncludeData(e.target.checked)}
                  />
                }
                label="Data"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={includeGiorni}
                    onChange={(e) => setIncludeGiorni(e.target.checked)}
                  />
                }
                label="Giorni"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={includePrezzo}
                    onChange={(e) => setIncludePrezzo(e.target.checked)}
                  />
                }
                label="Prezzo"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={onClose}>Annulla</MDButton>
          <MDButton onClick={handleGenerate} variant="gradient" color="info">
            Genera
          </MDButton>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <ZUTitleForm
            bgColor="info"
            title={sales ? "Ordini di vendita" : "Ordini noleggio"}
            icon="list_alt"
          />
          <MDBox p={3} lineHeight={1}>
            <Stack direction="row" justifyContent="space-between">
              {idCustomer && (
                <MDButton
                  color="secondary"
                  component={Link}
                  to="/customers"
                  sx={{ m: 1 }}
                >
                  indietro
                </MDButton>
              )}
              {idCustomer && (
                <MDButton
                  color="info"
                  component={Link}
                  to={`/customers/${idCustomer}/orders/new`}
                  m={1}
                >
                  aggiungi
                </MDButton>
              )}
              {!idCustomer && authService.isAdmin() && (
                <>
                  <MDButton
                    color="success"
                    component={MuiLink}
                    href={
                      config.SERVER_URL +
                      "/orders/download/excel?companyId=" +
                      rabbit.companyId
                    }
                    target="_blank"
                  >
                    esporta in excel
                  </MDButton>
                  <MDButton
                    color="info"
                    onClick={() => {
                      setReport(true);
                    }}
                  >
                    genera report
                  </MDButton>
                </>
              )}
              {!idCustomer && (
                <Autocomplete
                  disablePortal
                  id="stato"
                  value={stato}
                  options={[
                    "tutti",
                    "prossimi invii",
                    "prossimi ritiri",
                    "in consegna",
                  ]}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <MDInput {...params} label="stato" />
                  )}
                  onChange={(event, value) => {
                    handleStato(value);
                  }}
                />
              )}
              {!idCustomer && (
                <Autocomplete
                  disablePortal
                  id="anno"
                  value={"" + year}
                  options={[
                    "2020",
                    "2021",
                    "2022",
                    "2023",
                    "2024",
                    "2025",
                    "2026",
                    "2027",
                    "2028",
                    "2029",
                    "2030",
                  ]}
                  sx={{ width: 300 }}
                  renderInput={(params) => <MDInput {...params} label="Anno" />}
                  onChange={(event, value) => {
                    setYear(value);
                  }}
                />
              )}
            </Stack>
          </MDBox>
          <DataTable table={{ columns: columns, rows: filtered }} canSearch />
        </Card>
      </MDBox>
      <Footer />
      <Dialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confermi eliminazione?
        </DialogTitle>
        <DialogActions>
          <MDButton onClick={() => setOpenConfirm(false)}>Annulla</MDButton>
          <MDButton onClick={deleteObject} autoFocus>
            Conferma
          </MDButton>
        </DialogActions>
      </Dialog>
      <ReportDialog
        open={report}
        onClose={() => setReport(false)}
        onGenerate={handleGenerateReport}
      />
      <MDSnackbar
        color={showSnackbar.color}
        icon="notifications"
        title="Notifica"
        content={showSnackbar.msg}
        dateTime="adesso"
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: "", color: "info" })}
      />
    </DashboardLayout>
  );
}
