import React, { Component } from "react";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  InfoWindow,
  Marker,
} from "react-google-maps";
import Geocode from "react-geocode";
import Autocomplete from "react-google-autocomplete";
import { config } from "../../variables/Config";
Geocode.setApiKey(config.GOOGLE_MAPS_API);
Geocode.enableDebug();

class ZUMapGeocoder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      longAddress: "",
      address: "",
      civicNumber: "",
      postalCode: "",
      city: "",
      province: "",
      state: "",
      mapPosition: {
        lat: this.props.center.lat,
        lng: this.props.center.lng,
      },
      markerPosition: {
        lat: this.props.center.lat,
        lng: this.props.center.lng,
      },
    };
  }

  // Get the current address from the default map position and set those values in the state

  componentDidMount() {
    Geocode.fromLatLng(
      this.state.mapPosition.lat,
      this.state.mapPosition.lng
    ).then(
      (response) => {
        const longAddress = response.results[0].formatted_address;
        const addressArray = response.results[0].address_components;
        const city = this.getCity(addressArray);
        const address = this.getAddress(addressArray);
        const civicNumber = this.getCivicNumber(addressArray);
        const postalCode = this.getPostalCode(addressArray);
        const province = this.getProvince(addressArray);
        const state = this.getState(addressArray);

        this.setState({
          longAddress: longAddress || "",
          address: address || "",
          civicNumber: civicNumber || "",
          postalCode: postalCode || "",
          city: city || "",
          province: province || "",
          state: state || "",
        });
      },
      (error) => {
        console.error(error);
      }
    );
  }

  /**
   * Component should only update ( meaning re-render ), when the user selects the address, or drags the pin
   *
   * @param nextProps
   * @param nextState
   * @return {boolean}
   */
  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.markerPosition.lat == nextState.markerPosition.lat) {
      return false;
    }
    return true;
    // if (
    //   this.state.markerPosition.lat !== this.props.center.lat
    //   // ||
    //   // this.state.address !== nextState.address ||
    //   // this.state.city !== nextState.city ||
    //   // this.state.civicNumber !== nextState.civicNumber ||
    //   // this.state.state !== nextState.state
    // ) {
    //   return true
    // } else if (this.props.center.lat === nextProps.center.lat) {
    //   return false
    // }
  }

  /**
   * Get the city and set the city input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getCity = (addressArray) => {
    let city = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if (
            addressArray[i].types[j] === "sublocality_level_1" ||
            addressArray[i].types[j] === "locality"
          ) {
            city = addressArray[i].long_name;
            return city;
          }
        }
      }
    }
  };

  /**
   * Get the address and set the address input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getState = (addressArray) => {
    let obj = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if (addressArray[i].types[j] === "country") {
            obj = addressArray[i].long_name;
            return obj;
          }
        }
      }
    }
  };

  getProvince = (addressArray) => {
    let obj = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          addressArray[i].types[0] === "administrative_area_level_2"
        ) {
          obj = addressArray[i].short_name;
          return obj;
        }
      }
    }
  };

  getCivicNumber = (addressArray) => {
    let cn = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          addressArray[i].types[0] === "street_number"
        ) {
          cn = addressArray[i].long_name;
          return cn;
        }
      }
    }
  };

  getPostalCode = (addressArray) => {
    let pc = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          addressArray[i].types[0] === "postal_code"
        ) {
          pc = addressArray[i].long_name;
          return pc;
        }
      }
    }
  };

  getAddress = (addressArray) => {
    let obj = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (addressArray[i].types[0] && addressArray[i].types[0] === "route") {
          obj = addressArray[i].long_name;
          return obj;
        }
      }
    }
  };

  /**
   * And function for city,state and address input
   * @param event
   */
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  /**
   * This Event triggers when the marker window is closed
   *
   * @param event
   */
  onInfoWindowClose = (event) => {};

  /**
   * When the marker is dragged you get the lat and long using the functions available from event object.
   * Use geocode to get the address, city, area and state from the lat and lng positions.
   * And then set those values in the state.
   *
   * @param event
   */
  onMarkerDragEnd = (event) => {
    const newLat = event.latLng.lat();
    const newLng = event.latLng.lng();

    Geocode.fromLatLng(newLat, newLng).then(
      (response) => {
        const longAddress = response.results[0].formatted_address;
        const addressArray = response.results[0].address_components;
        const address = this.getAddress(addressArray);
        const civicNumber = this.getCivicNumber(addressArray);
        const city = this.getCity(addressArray);
        const postalCode = this.getPostalCode(addressArray);
        const province = this.getProvince(addressArray);
        const state = this.getState(addressArray);

        this.setState({
          longAddress: longAddress || "",
          address: address || "",
          civicNumber: civicNumber || "",
          postalCode: postalCode || "",
          province: province || "",
          city: city || "",
          state: state || "",
          markerPosition: {
            lat: newLat,
            lng: newLng,
          },
          mapPosition: {
            lat: newLat,
            lng: newLng,
          },
        });
        this.props.updateMapValue({
          longAddress: longAddress || "",
          address: address || "",
          civicNumber: civicNumber || "",
          postalCode: postalCode || "",
          province: province || "",
          city: city || "",
          state: state || "",
          markerPosition: {
            lat: newLat,
            lng: newLng,
          },
          mapPosition: {
            lat: newLat,
            lng: newLng,
          },
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  /**
   * When the user types an address in the search box
   * @param place
   */
  onPlaceSelected = (place) => {
    const longAddress = place.formatted_address;
    const addressArray = place.address_components;
    const address = this.getAddress(addressArray);
    const civicNumber = this.getCivicNumber(addressArray);
    const city = this.getCity(addressArray);
    const postalCode = this.getPostalCode(addressArray);
    const province = this.getProvince(addressArray);
    const state = this.getState(addressArray);
    const latValue = place.geometry.location.lat();
    const lngValue = place.geometry.location.lng();
    // Set these values in the state.
    this.setState({
      longAddress: longAddress || "",
      address: address || "",
      civicNumber: civicNumber || "",
      postalCode: postalCode || "",
      province: province || "",
      city: city || "",
      state: state || "",
      markerPosition: {
        lat: latValue,
        lng: lngValue,
      },
      mapPosition: {
        lat: latValue,
        lng: lngValue,
      },
    });
    this.props.updateMapValue({
      longAddress: longAddress || "",
      address: address || "",
      civicNumber: civicNumber || "",
      postalCode: postalCode || "",
      province: province || "",
      city: city || "",
      state: state || "",
      markerPosition: {
        lat: latValue,
        lng: lngValue,
      },
      mapPosition: {
        lat: latValue,
        lng: lngValue,
      },
    });
  };

  render() {
    const AsyncMap = withScriptjs(
      withGoogleMap((props) => (
        <GoogleMap
          google={this.props.google}
          defaultZoom={this.props.zoom}
          defaultCenter={{
            lat: this.state.mapPosition.lat,
            lng: this.state.mapPosition.lng,
          }}
        >
          {/* InfoWindow on top of marker */}
          <InfoWindow
            onClose={this.onInfoWindowClose}
            position={{
              lat: this.state.markerPosition.lat + 0.0018,
              lng: this.state.markerPosition.lng,
            }}
          >
            <div>
              <span style={{ padding: 0, margin: 0 }}>
                {this.state.longAddress}
              </span>
            </div>
          </InfoWindow>

          {/* Marker */}
          <Marker
            google={this.props.google}
            name="Dev-IT srl, Potenza"
            draggable
            onDragEnd={this.onMarkerDragEnd}
            position={{
              lat: this.state.markerPosition.lat,
              lng: this.state.markerPosition.lng,
            }}
          />
          <Marker />
          {/* For Auto complete Search Box */}
          <Autocomplete
            style={{
              width: "100%",
              height: "40px",
              paddingLeft: "16px",
              marginTop: "2px",
              marginBottom: "500px",
            }}
            onPlaceSelected={this.onPlaceSelected}
            language="it"
            options={{
              types: ["geocode", "establishment"],
              componentRestrictions: "it",
            }}
          />
        </GoogleMap>
      ))
    );
    let map;
    if (this.props.center.lat !== undefined) {
      map = (
        <AsyncMap
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${config.GOOGLE_MAPS_API}&libraries=places`}
          loadingElement={<div style={{ height: "100%" }} />}
          containerElement={<div style={{ height: this.props.height }} />}
          mapElement={<div style={{ height: "90%" }} />}
        />
      );
    } else {
      map = <div style={{ height: this.props.height }} />;
    }
    return map;
  }
}
export default ZUMapGeocoder;
